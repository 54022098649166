import { useEffect } from 'react';
import { routes } from '../pages/routes';
import { apiInstance } from '../services/helpers/apiInstance';

const useAxiosInterceptors = () => {
    useEffect(() => {
        const requestInterceptor = apiInstance.interceptors.request.use((config) => {
            return config;
        });

        const responseInterceptor = apiInstance.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (
                    423 === error.response.status &&
                    error.response.config.url === 'users/current' &&
                    window.location.pathname !== routes.NO_TENANT_NULIA_LICENSE_STATUS
                ) {
                    window.location.href = routes.NO_TENANT_NULIA_LICENSE_STATUS;
                }

                if (
                    403 === error.response.status &&
                    error.response.config.url === 'users/current' &&
                    window.location.pathname !== routes.NO_LICENSE_NULIA_LICENSE_STATUS
                ) {
                    window.location.href = routes.NO_LICENSE_NULIA_LICENSE_STATUS;
                }

                return Promise.reject(error);
            }
        );

        return () => {
            apiInstance.interceptors.request.eject(requestInterceptor);
            apiInstance.interceptors.response.eject(responseInterceptor);
        };
    }, []);
};

export default useAxiosInterceptors;
