import { Typography } from '@mui/material';
import styled from '../../../../../ui/theme/styled';

export const RootBox = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isClickable' && prop !== 'isStandardType'
})<{ isSelected: boolean; isClickable: boolean; isStandardType: boolean }>(
    ({ theme, isSelected, isClickable, isStandardType }) => ({
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: isStandardType ? 10 : '0 10px',
        fontSize: isStandardType ? 12 : 15,
        lineHeight: isStandardType ? '14px' : '24px',
        letterSpacing: isStandardType ? '0.25px' : '0.416px',
        '&:hover': isClickable && {
            cursor: 'pointer',
            backgroundColor: isSelected ? 'rgba(140, 140, 140, 1)' : theme.palette.background.default
        },
        color: isSelected ? theme.palette.common.white : isStandardType ? 'inherit' : 'rgba(0,0,0,0.88)',
        backgroundColor: isSelected ? 'rgba(140, 140, 140, 0.8)' : 'inherit',
        [theme.breakpoints.down('md')]: {
            fontSize: 10
        }
    })
);
