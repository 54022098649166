import { FC } from 'react';
import {
    RootBox,
    TextLogoBox,
    LogoBox,
    TextBox,
    TitleTypography,
    MessageTypography,
    ActionBox,
    BackgroundBox
} from './Style';
import PrimaryButton from '../../ui/buttons/primary-button/PrimaryButton';
import NuliaPurpleLogo from '../../assets/icons/NuliaPurpleLogo';

const NoTenantNuliaLicenseStatus: FC = () => {
    const onActionClickHandler = () => {
        window.open('https://nulia.cloud/');
    };
    return (
        <RootBox>
            <TextLogoBox>
                <LogoBox>
                    <NuliaPurpleLogo />
                </LogoBox>
                <TextBox>
                    <TitleTypography>Interested in Nulia Works?</TitleTypography>
                    <MessageTypography>
                        Your organization needs to be subscribed to Nulia Works to access this app. To learn more, visit
                        our website ​
                    </MessageTypography>
                    <ActionBox>
                        <PrimaryButton title='Visit website' clickHandler={onActionClickHandler} />
                    </ActionBox>
                </TextBox>
            </TextLogoBox>
            <BackgroundBox></BackgroundBox>
        </RootBox>
    );
};

export default NoTenantNuliaLicenseStatus;
