import { FC } from 'react';
import { useMsal } from '@azure/msal-react';
import SecondaryButton from '../../ui/buttons/secondary-button/SecondaryButton';
import LoginIcon from '../../assets/icons/LoginIcon';
import { DescriptionText, Main, RootContainer, TitleText } from './Style';

const Login: FC = () => {
    const msalInstance = useMsal();

    const login = () => {
        msalInstance.instance.loginRedirect();
    };

    return (
        <RootContainer>
            <header>
                <LoginIcon />
            </header>
            <Main>
                <TitleText>Welcome to Nulia Works!</TitleText>
                <DescriptionText>Unlocking the Value of Digital Productivity Suites</DescriptionText>
                <SecondaryButton onClick={login}>Login</SecondaryButton>
            </Main>
        </RootContainer>
    );
};

export default Login;
