import { FC } from 'react';
import { RootBox, TextLogoBox, LogoBox, TextBox, TitleTypography, MessageTypography, BackgroundBox } from './Style';
import NuliaPurpleLogo from '../../assets/icons/NuliaPurpleLogo';

const NoLicenseNuliaLicenseStatus: FC = () => {
    return (
        <RootBox>
            <TextLogoBox>
                <LogoBox>
                    <NuliaPurpleLogo />
                </LogoBox>
                <TextBox>
                    <TitleTypography>You’re Almost There!</TitleTypography>
                    <MessageTypography>
                        It looks like your organization is ready to unlock the value of Office 365 with the Nulia Works
                        Teams app. All you need now is a Nulia Works license. Reach out to your manager or IT
                        Administrator to find out how​
                    </MessageTypography>
                </TextBox>
            </TextLogoBox>
            <BackgroundBox></BackgroundBox>
        </RootBox>
    );
};

export default NoLicenseNuliaLicenseStatus;
