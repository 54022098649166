import { createContext, FC, useContext, PropsWithChildren, useState, useCallback, ChangeEvent, useEffect } from 'react';
import { matchPath, useLocation } from 'react-router';

export interface ITab {
    id: number;
    title: string;
    fullTitle: string;
    path: string | string[];
    isUserAllowedToAccess?: boolean;
    showLastUpdatedInfo?: boolean;
    children?: ITab[];
    alwaysVisible?: boolean;
}

export interface ITabsContext {
    tabs: ITab[];
    setTabs: (tabs: ITab[]) => void;
    activeTab?: ITab;
    searchText: string;
    changeSearchText: (e: ChangeEvent<HTMLInputElement>) => void;
    pageName?: string;
    setPageName: (pageName?: string) => void;
}

export const TabsContext = createContext<ITabsContext>({} as ITabsContext);

interface IProps {}

export const TabsProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const [tabs, setTabs] = useState<ITab[]>([]);
    const [activeTab, setActiveTab] = useState<ITab>();
    const [searchText, setSearchText] = useState<string>('');
    const [pageName, setPageName] = useState<string | undefined>(undefined);
    const location = useLocation();
    // useAxiosInterceptors();

    useEffect(() => {
        if (tabs && tabs.length > 0) {
            const newActiveTab = tabs.find((tab) => {
                if (typeof tab.path === 'string' && !tab.children) {
                    return tab.isUserAllowedToAccess && matchPath(tab.path, location.pathname);
                } else if (tab.children) {
                    const matchedTab = tab.children.find((childrenTab) => {
                        if (typeof childrenTab.path === 'string') {
                            return (
                                childrenTab.isUserAllowedToAccess &&
                                matchPath(childrenTab.path as string, location.pathname)
                            );
                        } else {
                            return childrenTab.children?.find((innerChildrenTab) => {
                                return (
                                    (innerChildrenTab.isUserAllowedToAccess &&
                                        matchPath(innerChildrenTab.path as string, location.pathname)) !== undefined
                                );
                            });
                        }
                    });
                    if (matchedTab) return matchedTab;
                    if (matchPath(tab.path as string, location.pathname) && tab.children?.length > 0) {
                        return tab.children.find((tab) => tab.isUserAllowedToAccess);
                    }
                    return false;
                } else {
                    if (Array.isArray(tab.path))
                        return tab.path.find((tabPath: string) => {
                            return matchPath(tabPath, location.pathname);
                        });
                    return null;
                }
            });
            if (newActiveTab) {
                setActiveTab(newActiveTab);
            } else {
                setActiveTab(tabs.find((tab) => tab.isUserAllowedToAccess));
            }
        }
        setSearchText('');
    }, [location, tabs]);

    const changeSearchText = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    }, []);

    const tabsContext: ITabsContext = { tabs, activeTab, changeSearchText, searchText, setTabs, pageName, setPageName };

    return <TabsContext.Provider value={tabsContext}>{children}</TabsContext.Provider>;
};

export const useTabsStateValue: () => ITabsContext = () => useContext(TabsContext);
