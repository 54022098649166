import { Configuration } from '@azure/msal-browser';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_PLATFORM_APP_ID as string,
        redirectUri: location.hostname === 'localhost' ? 'http://localhost:3000' : `https://${location.hostname}/`
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true,
        secureCookies: true
    },
    tokenRenewalOffsetSeconds: 300,
    system: {
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
        asyncPopups: false,
        // @ts-ignore
        tokenRenewalHandler: (callback: any, error: any) => {
            if (callback) {
                callback();
            } else {
                console.error('Token renewal failed: ', error);
            }
        }
    }
};
