import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Behavior from '../behavior/Behavior';
import { EBehaviorLevel } from '../../../../../interfaces/enums/EBehaviorLevel';
import { IBehavior } from '../../../../../interfaces/IBehavior';
import { IActivity } from '../../../../../interfaces/IActivity';
import { useSkillStateValue } from '../../../../../contexts/SkillContext';
import {
    DescriptionItemsBox,
    HeaderBox,
    HrLine,
    PointsBox,
    PointsCountBox,
    PointsEarnedSpan,
    PointsMaxSpan,
    RootBox,
    TitleSpan,
    YourPointsLabel
} from './Style';

interface IProps {
    index: number;
    title: string;
    pointsEarned?: number;
    pointsMax?: number;
    descriptionItems?: string[];
    behaviors?: IBehavior[];
    id: EBehaviorLevel;
    onBehaviorGroupSelect?: (id: EBehaviorLevel, expanded: boolean) => void;
    isSelected: boolean;
    isStandardSkillType: boolean;
}

const BehaviorLevel: FC<IProps> = ({
    index,
    id,
    title,
    pointsEarned,
    pointsMax,
    behaviors,
    onBehaviorGroupSelect,
    isSelected,
    isStandardSkillType
}) => {
    const [isExpanded, setExpanded] = useState<boolean>(false); //is selected
    const { setActivities, setSelectedBehavior, selectedBehavior, setSelectedBehaviorLevel } = useSkillStateValue();
    const [_, setSearchParams] = useSearchParams();
    const { t } = useTranslation();

    const onHeaderClick = () => {
        if (onBehaviorGroupSelect) onBehaviorGroupSelect(id, !isExpanded);
        if (isExpanded) setSelectedBehavior(null);
        setExpanded((expanded) => !expanded);
    };

    const onBehaviorClick = (id: number, behaviorTitle: string, activities?: IActivity[]) => {
        if (!isStandardSkillType) return;
        setActivities(activities ?? null);
        setSelectedBehaviorLevel(null);
        if (behaviorTitle === selectedBehavior?.title) {
            setSelectedBehavior(null);
            // if (onBehaviorGroupSelect) onBehaviorGroupSelect(id, false);
        } else {
            // if (onBehaviorGroupSelect) onBehaviorGroupSelect(id, false);
            setSearchParams((prev) => {
                prev.set('behavior', id.toString());
                prev.delete('activity');
                return prev;
            });
            setSelectedBehavior({
                id,
                title: behaviorTitle,
                activities: activities ?? []
            });
        }
    };

    return (
        <RootBox className={`behavior-level-${index || 0}`}>
            {isStandardSkillType && (
                <HeaderBox isClickable={isStandardSkillType} isselected={isSelected ? 1 : 0} onClick={onHeaderClick}>
                    {/* {isExpanded ? (
                    <ArrowDropDown className={classes.expandedIcon} />
                ) : (
                    <ArrowRight className={classes.collapsedIcon} />
                )} */}
                    <TitleSpan isselected={isSelected ? 1 : 0}>{title}</TitleSpan>
                    <PointsBox>
                        <YourPointsLabel isselected={isSelected ? 1 : 0}>
                            {t('components.behavior.pointsCollected')}
                        </YourPointsLabel>
                        <PointsCountBox>
                            <PointsEarnedSpan isselected={isSelected ? 1 : 0}>{pointsEarned ?? ''}</PointsEarnedSpan>
                            <PointsMaxSpan isselected={isSelected ? 1 : 0}>/{pointsMax ?? ''}</PointsMaxSpan>
                        </PointsCountBox>
                    </PointsBox>
                </HeaderBox>
            )}
            {(isExpanded || !isExpanded) && (
                <DescriptionItemsBox>
                    {behaviors?.map((item, itemIndex) => {
                        return (
                            <Behavior
                                isSelected={isStandardSkillType && selectedBehavior?.title === item.title}
                                key={itemIndex}
                                title={item.title}
                                id={item.id}
                                behaviorClickHandler={onBehaviorClick}
                                activities={item.activities}
                                index={itemIndex}
                                behaviorLevel={index}
                                isStandardType={isStandardSkillType}
                            />
                        );
                    })}
                </DescriptionItemsBox>
            )}
            {isStandardSkillType && <HrLine />}
        </RootBox>
    );
};

export default BehaviorLevel;
