import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RootBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
});

export const RootInnerBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    width: '1040px',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        marginLeft: 'unset',
        paddingLeft: '20px',
        paddingRight: '20px'
    }
}));

export const RootPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    padding: '18px',
    borderRadius: theme.borderRadius?.outside?.small
}));

export const CategoriesWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isLoading'
})<{ isLoading: boolean }>(({ isLoading, theme }) => ({
    display: 'grid',
    width: '100%',
    gridTemplateColumns: isLoading ? '1fr' : '1fr 1fr',
    padding: '36px 0',
    gap: '10px',
    [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '1fr'
    }
}));

export const CategoryBox = styled('div')({
    display: 'grid',
    gridTemplateColumns: '196px 1fr',
    cursor: 'pointer',
    padding: '2px',
    '&:hover': {
        boxShadow: '0px 0px 6px #654ea3, 0px 0px 4px #654ea3'
    }
});

export const CategoryInfo = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
});

export const CategoryInfoTitleTypography = styled(Typography)({});

export const CategoryInfoDescriptionTypography = styled(Typography)({
    display: '-webkit-box',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden'
});

export const TitleTypography = styled(Typography)({
    paddingBottom: '18px',
    borderBottom: '1px solid #00000029',
    color: '#616063',
    lineHeight: '32px',
    minHeight: '50.8px'
});

export const CategoryImage = styled('img')({
    width: '180px',
    height: '130px',
    visibility: 'hidden'
});
