import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { MARKETING_PATH } from '../../utils/constants';

export const RootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'calc(100% - 100px)',
    margin: '50px',
    backgroundColor: '#FAFAFA',
    height: 'calc(100vh - 125px)',
    borderRadius: '4px 0 4px 0',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse'
    }
}));

export const TextLogoBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    padding: '32px',
    gap: '40px',
    [theme.breakpoints.down('md')]: {
        gap: 0,
        paddingTop: '8px'
    }
}));

export const TextBox = styled('div')(({ theme }) => ({
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px'
}));

export const LogoBox = styled('div')(({ theme }) => ({
    display: 'flex',
    height: '150px',
    paddingTop: '44px',
    [theme.breakpoints.down('md')]: {
        height: '80px',
        paddingTop: '8px'
    }
}));

export const LogoImg = styled('img')({});

export const TitleTypography = styled(Typography)(({ theme }) => ({
    fontSize: '34px',
    lineHeight: '40px',
    color: theme.palette.common.black,
    fontWeight: 300,
    [theme.breakpoints.down('md')]: {
        fontSize: '24px',
        lineHeight: '28px'
    }
}));

export const MessageTypography = styled(Typography)(({ theme }) => ({
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.6)',
    [theme.breakpoints.down('md')]: {
        fontSize: '18px',
        lineHeight: '22px'
    }
}));

export const BackgroundBox = styled('div')(({ theme }) => ({
    backgroundImage: `url(${MARKETING_PATH}teams_no_access.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'right top',
    flex: 1,
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
        height: '200px'
    }
}));
