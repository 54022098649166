import { Divider, Link, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { default as MuiBreadcrumbs } from '@mui/material/Breadcrumbs';

export const RootBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
}));

export const RootInnerBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    width: '1040px',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        marginLeft: 'unset',
        paddingLeft: '20px',
        paddingRight: '20px'
    }
}));

export const RootPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    padding: '18px',
    borderRadius: theme.borderRadius?.outside?.small,
    [theme.breakpoints.down('lg')]: {
        width: '100%'
    }
}));

export const Header = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '140px auto 140px',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    [theme.breakpoints.down(770)]: {
        display: 'grid',
        gridTemplateRows: '1fr 1fr',
        gridTemplateColumns: 'auto auto',
        gap: '16px'
    }
}));

export const DropdownBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(770)]: {
        gridArea: '2/ span 2',
        gap: '10px'
    }
}));

export const IframeWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 115px)',
    padding: 32,
    width: 1040,
    [theme.breakpoints.down('lg')]: {
        width: '100%'
    },
    [theme.breakpoints.down(770)]: {
        padding: '20px 2px'
    },
    [theme.breakpoints.down('sm')]: {
        width: 330
    }
}));

export const StyledHTMLIframe = styled('iframe')({
    width: '1px',
    minWidth: '100%',
    flex: 1,
    border: 'none',
    height: 'auto'
});

export const BreadcrumbsBox = styled(MuiBreadcrumbs)({
    '& .MuiBreadcrumbs-li, & .MuiBreadcrumbs-separator': {
        marginBottom: 0
    },
    '& .MuiBreadcrumbs-separator': {
        marginTop: '2px',
        fontWeight: 700,
        marginRight: '14px',
        marginLeft: '14px'
    }
});

export const BreadcrumbLink = styled(Link)({
    color: 'rgb(101, 78, 163)',
    fontWeight: 800,
    letterSpacing: '0.5px',
    textDecoration: 'none',
    '&:not(.last-child):hover': {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    '&.last-child:hover': {
        cursor: 'default'
    }
});

export const HowToSectionDivider = styled(Divider)({
    height: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    marginTop: 10,
    marginBottom: 10
});
