import { pulseKeyFrame } from '../../../css/CommonComponents';
import styled from '../../theme/styled';
import { Typography } from '@mui/material';

export const RootBox = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '&:not(:last-child)': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    }
}));

export const ImageBox = styled('div')({
    maxWidth: 140
});

export const SelectBox = styled('div')({
    paddingLeft: '12px'
});

export const InfoBox = styled('div')({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    paddingLeft: 16,
    minHeight: 90,
    justifyContent: 'flex-end'
});

export const InfoTextBox = styled(Typography)({
    color: 'rgba(0, 0, 0, 0.8)',
    fontSize: '16px'
});

export const AppsBox = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '14%',
    paddingTop: '10px',
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap'
    }
}));

export const AppIconWrapperBox = styled('div')({
    display: 'flex',
    gap: 18,
    flexWrap: 'wrap'
});

export const AppIconImg = styled('img')({
    width: 26,
    height: 26
});

export const ShowDescriptionBox = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '14px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: theme.palette.primary.main,
    letterSpacing: '1.25px',
    lineHeight: '22px',
    fontSize: 10,
    fontWeight: 500,
    textTransform: 'uppercase',
    borderRadius: '3px',
    minWidth: 140,
    fontFamily: 'Ubuntu',
    '&:hover': {
        textDecoration: 'underline'
    }
}));

export const CompanyFocusDescriptionWrapperBox = styled('div')({
    display: 'flex',
    gap: '14px',
    alignItems: 'center',
    justifyContent: 'flex-end'
});

export const MasterLevelRequestedWrapper = styled('span')({
    display: 'flex',
    alignItems: 'center'
});

export const FirstRowBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '2px 8px',
    cursor: 'pointer',
    minHeight: '136px',
    '&:hover': {
        backgroundColor: theme.palette.background.default
    }
}));

export const CollapsableRowBox = styled('div')({
    padding: '10px 16px 16px 35px'
});

export const Description = styled(Typography)({
    color: 'rgba(0, 0, 0, 0.88)',
    flex: 2
});

export const MainContentWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isPaddingNeeded'
})<{ isPaddingNeeded?: boolean }>(({ isPaddingNeeded }) => ({
    display: 'flex',
    flexDirection: 'row',
    paddingTop: isPaddingNeeded ? 20 : 0,
    width: '100%'
}));

export const RightSideWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
});

export const BadgesBox = styled('div')({
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    gap: 15,
    marginTop: 12,
    marginBottom: 7,
    paddingRight: 36
});

export const NumberOfUsersBox = styled('div')({
    textDecoration: 'underline',
    fontFamily: 'Open Sans',
    fontSize: 12,
    lineHeight: '20px',
    letterSpacing: '0.5px'
});

export const VideoDescriptionBox = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '30px',
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        flexDirection: 'column'
    }
}));

export const BasicSkilListWrapper = styled('div')({
    paddingTop: '28px'
});

export const CompanyFocusActionBox = styled('div')<{ isActive: boolean }>(({ isActive, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    animation: isActive ? `${pulseKeyFrame} 0.6s 4 alternate` : 'none',
    padding: '8px',
    background: theme.palette.common.white,
    borderRadius: '12px'
}));

export const DescriptionSegment = styled('div')({
    paddingTop: '4px'
});
