import { createContext, FC, useContext, PropsWithChildren, useEffect, useState, useRef, useCallback } from 'react';
import { isBefore } from 'date-fns';
import { useTheme } from '@mui/system';
import {
    useGetInsightsOrganizationBadgesQuery,
    useGetInsightsOrganizationalBadgesQuery
} from '../services/InsightsQueryService';
import { IScoreLine } from '../interfaces/IScoreLine';
import { EGraphCardSelect } from '../interfaces/enums/EGraphCardSelect';
import useFilterSearch from '../hooks/useFilterSearch';
import { IInsightsOrganizationBadgesVM } from '../interfaces/views/IInsightsOrganizationBadgesVM';
import { IFilterCategories, IFilterCategoryValue } from '../ui/filters/filters/Filters';
import {
    insightsOrganizationBadgesByDepartmentFilterCallback,
    insightsOrganizationBadgesByRegionFilterCallback,
    insightsOrganizationBadgesCategories
} from './util/filterCategories';
import expandDatesToLastYearPerWeek from '../utils/expandDatesToLastYearPerWeek';
import { IOrganizationalBadgeVM } from '../interfaces/views/IOrganizationalBadgeVM';

export interface IInsightsOrganizationBadgesContext {
    data: IInsightsOrganizationBadgesVM[];
    latestDateData: IInsightsOrganizationBadgesVM[];
    scoreLines?: IScoreLine[];
    searchText: string;
    setSearchText: (searchText: string) => void;
    changeScoreLinesInterval: (option: EGraphCardSelect) => void;
    filterCategories: IFilterCategories[];
    onFilterValueChange: (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => void;
    isBadgesDataLoading: boolean;
    isOrganizationalBadgesDataLoading: boolean;
    badgesTableData?: IOrganizationalBadgeVM[];
}

export const InsightsOrganizationBadgesContext = createContext<IInsightsOrganizationBadgesContext>(
    {} as IInsightsOrganizationBadgesContext
);

export const InsightsOrganizationBadgesProvider: FC<PropsWithChildren> = ({ children }) => {
    // Data used for Insights Organizational Engagement graph
    const [data, setData] = useState<IInsightsOrganizationBadgesVM[]>([]);
    const [filteredData, setFilteredData] = useState<IInsightsOrganizationBadgesVM[]>([]);
    // ---

    // Data used for Insights Organizational Engagement table
    const [latestDateData, setLatestDateData] = useState<IInsightsOrganizationBadgesVM[]>([]);
    const [filteredLatestDateData, setFilteredLatestDateData] = useState<IInsightsOrganizationBadgesVM[]>([]);
    // ---

    const [filterCategories, setFilterCategories] = useState<IFilterCategories[]>(insightsOrganizationBadgesCategories);
    const [scoreLines, setScoreLines] = useState<IScoreLine[] | undefined>();
    const scoreLinesAllOptionsRef = useRef<IScoreLine[][]>([]);
    const { data: badgesData, isLoading: isBadgesDataLoading } = useGetInsightsOrganizationBadgesQuery();
    const [_, setBadgesMapByDate] = useState<Map<string, IInsightsOrganizationBadgesVM[]>>(new Map());
    const [filteredBadgesMapByDate, setFilteredBadgesMapByDate] = useState<
        Map<string, IInsightsOrganizationBadgesVM[]>
    >(new Map());
    const [activeFilters, setActiveFilters] = useState<string[]>([]);
    const newestDateRef = useRef<Date>();
    const theme = useTheme();
    const { data: fetchedOrganizationalBadgeData, isLoading: isOrganizationalBadgesDataLoading } =
        useGetInsightsOrganizationalBadgesQuery();

    const generateMapByDateBasedOnData: (
        data: IInsightsOrganizationBadgesVM[]
    ) => Map<string, IInsightsOrganizationBadgesVM[]> | undefined = (data) => {
        if (data) {
            const competenceMapByDate = new Map();
            data.forEach((item) => {
                if (item.date && competenceMapByDate.has(item.date.toDateString())) {
                    competenceMapByDate.set(item.date.toDateString(), [
                        ...competenceMapByDate.get(item.date.toDateString()),
                        item
                    ]);
                } else {
                    competenceMapByDate.set(item.date.toDateString(), [item]);
                }
            });
            return competenceMapByDate;
        }
        return;
    };

    useEffect(() => {
        if (filteredData) {
            const filteredUserIdList = filteredData.map((item) => item.userId);
            setFilteredLatestDateData(
                latestDateData.filter((dataItem) => {
                    return filteredUserIdList.includes(dataItem.userId);
                })
            );
            const competenceMapByDate = generateMapByDateBasedOnData(
                filteredData.sort((a, b) => (isBefore(a.date as Date, b.date as Date) ? -1 : 1))
            );
            if (competenceMapByDate) {
                setFilteredBadgesMapByDate(competenceMapByDate);
            }
        }
    }, [filteredData]);

    useEffect(() => {
        if (badgesData) {
            const mapByDate = new Map();
            badgesData.forEach((item) => {
                if (item.date && mapByDate.has(item.date.toDateString())) {
                    mapByDate.set(item.date.toDateString(), [...mapByDate.get(item.date.toDateString()), item]);
                } else {
                    mapByDate.set(item.date.toDateString(), [item]);
                }
            });
            setBadgesMapByDate(mapByDate);
        }
    }, [badgesData]);

    useEffect(() => {
        if (badgesData) {
            const departments = new Set<string>();
            const regions = new Set<string>();

            badgesData.forEach((dataItem) => {
                if (dataItem.department) departments.add(dataItem.department);
                if (dataItem.region) regions.add(dataItem.region);
            });

            filterCategories.forEach((filterCategory) => {
                if (filterCategory.radioGroupId === 'department') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    departments.forEach((department) => {
                        categoryValues.push({
                            key: department,
                            name: department,
                            callback: insightsOrganizationBadgesByDepartmentFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
                if (filterCategory.radioGroupId === 'region') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    regions.forEach((region) => {
                        categoryValues.push({
                            key: region,
                            name: region,
                            callback: insightsOrganizationBadgesByRegionFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
            });
            setFilterCategories([...filterCategories]);
        }
    }, [badgesData]);

    useEffect(() => {
        if (filteredBadgesMapByDate && filteredBadgesMapByDate.size > 0) {
            const sharedScoreLine: IScoreLine = {
                id: 'W1',
                name: 'Shared',
                color: '#349FEE',
                scores: []
            };

            const claimedScoreLine: IScoreLine = {
                id: 'W2',
                name: 'Claimed',
                color: theme.palette.status.attained,
                scores: []
            };

            const readyToClaimScoreLine: IScoreLine = {
                id: 'W3',
                name: 'Ready To Claim',
                color: '#5C4F9C',
                scores: []
            };

            const needAttentionScoreLine: IScoreLine = {
                id: 'W4',
                name: 'Need Attention',
                color: theme.palette.status.needAttention,
                legendColor: '#ED8000',
                scores: []
            };

            filteredBadgesMapByDate.forEach(function (mapValue, mapKey) {
                let sharedPerDateTotal = 0;
                let claimedPerDateTotal = 0;
                let readyToClaimPerDateTotal = 0;
                let needAttentionPerDateTotal = 0;

                mapValue.forEach((mapItem) => {
                    sharedPerDateTotal += mapItem.shared;
                    claimedPerDateTotal += mapItem.claimed;
                    readyToClaimPerDateTotal += mapItem.readyToClaim;
                    needAttentionPerDateTotal += mapItem.needAttention;
                });

                sharedScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: sharedPerDateTotal
                });

                claimedScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: claimedPerDateTotal
                });

                readyToClaimScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: readyToClaimPerDateTotal
                });

                needAttentionScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: needAttentionPerDateTotal
                });

                const allIndividualScoreTimes = [
                    expandDatesToLastYearPerWeek(sharedScoreLine),
                    expandDatesToLastYearPerWeek(claimedScoreLine),
                    expandDatesToLastYearPerWeek(readyToClaimScoreLine),
                    expandDatesToLastYearPerWeek(needAttentionScoreLine)
                ];
                const scoreLines4Month = allIndividualScoreTimes.map((scoreTime) => {
                    return {
                        ...scoreTime,
                        scores: scoreTime.scores.slice(-15)
                    };
                });
                setScoreLines(scoreLines4Month);

                scoreLinesAllOptionsRef.current = [scoreLines4Month, allIndividualScoreTimes];
            });
        } else {
            setScoreLines([]);
        }
    }, [filteredBadgesMapByDate]);

    const changeScoreLinesInterval = (option: EGraphCardSelect) => {
        if (option === EGraphCardSelect.MONTH_4) {
            setScoreLines(scoreLinesAllOptionsRef.current[0]);
        } else if (option === EGraphCardSelect.YEAR_WITH_WEEKS) {
            setScoreLines(scoreLinesAllOptionsRef.current[1]);
        }
    };

    const onAnythingChange: any = useCallback(
        (organizationBadgesDataNeedToBeFiltered: IInsightsOrganizationBadgesVM[], runSearchText?: boolean) => {
            let newFilteredBadgesData = [...organizationBadgesDataNeedToBeFiltered];

            if (runSearchText) {
                newFilteredBadgesData = instantFilterByText(searchText, newFilteredBadgesData);
                setFilteredData(newFilteredBadgesData);
            } else {
                onFilterValueChange(filterCategories, activeFilters, true);
                setFilteredData(newFilteredBadgesData);
            }
            return newFilteredBadgesData;
        },
        [
            badgesData,
            // @ts-ignore
            searchText,
            // @ts-ignore
            activeFilters,
            filterCategories
        ]
    );

    const onFilterValueChange = (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => {
        setActiveFilters(activeFilters);
        let newFilteredData = [...data];
        filterCategories.forEach((filterCategory) => {
            if (filterCategory.values) {
                filterCategory.values!.forEach((filterCategoryValue) => {
                    if (filterCategoryValue.callback && activeFilters.includes(filterCategoryValue.key)) {
                        newFilteredData = newFilteredData.filter((dataItem) => {
                            if (filterCategoryValue.callback) {
                                const isValid = filterCategoryValue.callback(
                                    dataItem,
                                    filterCategoryValue.name,
                                    filterCategoryValue.key
                                );
                                return isValid;
                            }
                            return false;
                        });
                    }
                });
            }
        });
        if (!dontRunAnythingChange) onAnythingChange(newFilteredData, true);
    };

    const { searchText, setSearchText, instantFilterByText } = useFilterSearch<IInsightsOrganizationBadgesVM>({
        data: data,
        dataSerachablePropertyName: 'displayName',
        setDataCallback: setFilteredData,
        onChangeCallback: onAnythingChange
    });

    useEffect(() => {
        if (badgesData) {
            setData(badgesData);
            setFilteredData(badgesData);
            const newestDate = badgesData.reduce(
                (a, b) => {
                    return a.date > b.date ? a : b;
                },
                {
                    date: new Date(1970, 1, 1)
                }
            ).date;
            newestDateRef.current = newestDate;
            const latestDataItems = badgesData.filter((dataItem) => {
                if (dataItem.date.getTime() === newestDate.getTime()) return true;
                return false;
            });
            setLatestDateData(latestDataItems);
            setFilteredLatestDateData(latestDataItems);
        }
    }, [badgesData]);

    const insightsOrganizationBadgesContext: IInsightsOrganizationBadgesContext = {
        data: filteredData,
        latestDateData: filteredLatestDateData,
        scoreLines,
        searchText,
        setSearchText,
        changeScoreLinesInterval,
        filterCategories,
        onFilterValueChange,
        isBadgesDataLoading,
        isOrganizationalBadgesDataLoading,
        badgesTableData: fetchedOrganizationalBadgeData
    };

    return (
        <InsightsOrganizationBadgesContext.Provider value={insightsOrganizationBadgesContext}>
            {children}
        </InsightsOrganizationBadgesContext.Provider>
    );
};

export const useInsightsOrganizationBadgesStateValue: () => IInsightsOrganizationBadgesContext = () =>
    useContext(InsightsOrganizationBadgesContext);
