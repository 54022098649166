import { FC, useCallback, useEffect, useMemo, useState, KeyboardEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme, Skeleton } from '@mui/material';
import { IAppDTO } from '../../../interfaces/dtos/IAppDTO';
import useExpand from '../../../hooks/useExpand';
import AssignedControl from '../../general/assigned-control/AssignedControl';
import Tooltip from '../../general/tooltip/Tooltip';
import DottedCircle from '../../general/dotted-circle/DottedCircle';
import { EBadgeLevel } from '../../../interfaces/enums/EBadgeLevel';
import { ESkillLevel } from '../../../interfaces/enums/ESkillLevel';
import { IAssignmentDTO } from '../../../interfaces/dtos/IAssignmentDTO';
import { ECompetenceState } from '../../../interfaces/enums/ECompetenceState';
import BlueWarningIcon from '../../../assets/icons/BlueWarningIcon';
import {
    RootBox,
    FirstRowBox,
    AppIconImg,
    AppIconWrapperBox,
    AppsBox,
    AssignedControlWrapper,
    BadgesBox,
    CollapsableRowBox,
    Description,
    FooterActionBox,
    ImageBox,
    InfoAssignedControlWrapper,
    InfoBox,
    InfoTextBox,
    MasterLevelRequestedWrapper,
    ShowDescriptionBox,
    ActionControlRows,
    ActionControlRow,
    DescriptionActionControlRow,
    MdDownActionControlRow,
    MdDownChartDescriptionBox,
    CompanyImportantWrapper,
    DescriptionSegment,
    OrgPriorityMasterLevelBox,
    OutcomeImg
} from './Style';
import { createUrl } from '../../../utils/createUrl';
import UserNotAchieved from '../../../assets/icons/badge-statuses/UserNotAchieved';
import UserClaimed from '../../../assets/icons/badge-statuses/UserClaimed';
import ProducerNeedsAttention from '../../../assets/icons/badge-statuses/ProducerNeedsAttention';
import ProducerExpired from '../../../assets/icons/badge-statuses/ProducerExpired';
import ProducerAvailableToClaim from '../../../assets/icons/badge-statuses/ProducerAvailableToClaim';
import ProducerClaimed from '../../../assets/icons/badge-statuses/ProducerClaimed';
import ProducerNotAchieved from '../../../assets/icons/badge-statuses/ProducerNotAchieved';
import MasterNotAchieved from '../../../assets/icons/badge-statuses/MasterNotAchieved';
import MasterNeedsAttention from '../../../assets/icons/badge-statuses/MasterNeedsAttention';
import MasterExpired from '../../../assets/icons/badge-statuses/MasterExpired';
import MasterAvailableToClaim from '../../../assets/icons/badge-statuses/MasterAvailableToClaim';
import MasterClaimed from '../../../assets/icons/badge-statuses/MasterClaimed';
import ClipboardCheckIcon from '../../../assets/icons/ClipboardCheck';
import fetchImageErrorCallback from '../../../utils/fetchImageErrorCallback';
import { useOutcomesStateValue } from '../../../contexts/OutcomesContext';
interface IProps {
    id: number;
    name: string;
    groupName?: string;
    isAssigned: boolean;
    appIcons: IAppDTO[];
    outcomeClickHandler?: (id: string) => void;
    description?: string;
    isAssignedControlActive?: boolean;
    totalSkillNumber?: number;
    totalInProgressSkillNumber?: number;
    totalAchievedSkillNumber?: number;
    totalNeedAttentionSkillNumber?: number;
    badges?: { state?: EBadgeLevel; level: ESkillLevel }[];
    isUserFocus: boolean;
    isCompanyImportant: boolean;
    isMasterLevelRequested: boolean;
    assignment: IAssignmentDTO;
    translations?: {
        assignedTitle?: string;
        notAssignedTitle?: string;
        assignTooltipText?: string;
        unassignTooltipText?: string;
    };
    toggleAssignCallback?: (outcomeId: number, isAssigned: boolean) => void;
    index?: number;
    outcomeImage?: string;
    isActionDisabled?: boolean;
}

const OutcomeCard: FC<IProps> = ({
    id,
    name,
    appIcons,
    outcomeClickHandler,
    description,
    isAssignedControlActive,
    totalSkillNumber,
    totalInProgressSkillNumber,
    totalAchievedSkillNumber,
    totalNeedAttentionSkillNumber,
    badges,
    isCompanyImportant,
    isMasterLevelRequested,
    isAssigned,
    assignment,
    translations,
    toggleAssignCallback,
    index,
    outcomeImage,
    isActionDisabled
}) => {
    const { t } = useTranslation();
    const { collapse, expand, isExpanded } = useExpand({
        defaultExpanded: false
    });
    const [isSkillAssigned, setSkillAssigned] = useState(isAssigned);
    const toggleDescriptionRef = useRef<HTMLDivElement | null>(null);
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const { isPostAssignOutcomeLoading } = useOutcomesStateValue();

    useEffect(() => {
        setSkillAssigned(isAssigned);
    }, [isAssigned]);

    const toggleAssign = (assigned: boolean) => {
        setSkillAssigned(assigned);
        if (toggleAssignCallback) toggleAssignCallback(id, assigned);
    };

    const outcomeBadgeMemo = useMemo(() => {
        return (
            <DottedCircle
                numberOfDots={totalSkillNumber || 0}
                numberOfInProgressDots={totalInProgressSkillNumber || 0}
                numberOfAchievedDots={totalAchievedSkillNumber || 0}
                numberOfNeedAttentionDots={totalNeedAttentionSkillNumber || 0}
                isMaster={assignment.level === ESkillLevel.MASTER}
                isAssigned={assignment.level !== ESkillLevel.NONE}
                index={index}
            >
                {outcomeImage ? (
                    <OutcomeImg
                        alt='outcome'
                        src={createUrl(outcomeImage)?.generatedUrl}
                        onError={fetchImageErrorCallback}
                    />
                ) : (
                    <Skeleton variant='circular' height={70} width={70} />
                )}
            </DottedCircle>
        );
    }, [totalSkillNumber, totalInProgressSkillNumber, totalAchievedSkillNumber, assignment, index]);

    const badgesMemo = useMemo(() => {
        if (badges)
            return badges.map((badgeSkillLevel) => {
                const { state, level } = badgeSkillLevel;
                if (state && level) {
                    switch (level) {
                        case ESkillLevel.USER:
                            switch (state) {
                                case EBadgeLevel.NONE:
                                case EBadgeLevel.EARNED:
                                    return <UserNotAchieved />;
                                case EBadgeLevel.CLAIMED:
                                    return <UserClaimed />;
                                default:
                                    return <></>;
                            }
                        case ESkillLevel.PRODUCER:
                            switch (state) {
                                case EBadgeLevel.NONE:
                                    return <ProducerNotAchieved />;
                                case EBadgeLevel.NEEDS_ATTENTION:
                                    return <ProducerNeedsAttention />;
                                case EBadgeLevel.EXPIRED:
                                    return <ProducerExpired />;
                                case EBadgeLevel.EARNED:
                                    return <ProducerAvailableToClaim />;
                                case EBadgeLevel.CLAIMED:
                                    return <ProducerClaimed />;
                            }
                        case ESkillLevel.MASTER:
                            switch (state) {
                                case EBadgeLevel.NONE:
                                    return <MasterNotAchieved />;
                                case EBadgeLevel.NEEDS_ATTENTION:
                                    return <MasterNeedsAttention />;
                                case EBadgeLevel.EXPIRED:
                                    return <MasterExpired />;
                                case EBadgeLevel.EARNED:
                                    return <MasterAvailableToClaim />;
                                case EBadgeLevel.CLAIMED:
                                    return <MasterClaimed />;
                            }
                    }
                }
                return null;
            });
        return null;
    }, [badges]);

    const userOutcomeState = useMemo(() => {
        if (assignment.level === ESkillLevel.MASTER) return assignment.masterState;
        if (assignment.level === ESkillLevel.PRODUCER) return assignment.producerState;
        return undefined;
    }, [assignment]);

    const appIconsMemo = useMemo(() => {
        if (appIcons)
            return (
                <AppIconWrapperBox>
                    {appIcons.map((appIcon) => {
                        return (
                            <AppIconImg
                                key={appIcon.name}
                                alt={appIcon.name}
                                src={createUrl(appIcon.icon)?.generatedUrl}
                                onError={fetchImageErrorCallback}
                            />
                        );
                    })}
                </AppIconWrapperBox>
            );
        return <></>;
    }, [appIcons]);

    const masterLevelRequestedMemo = useMemo(() => {
        return isMasterLevelRequested ? (
            <Tooltip title={t('tooltips.masterLevelRequested')}>
                <MasterLevelRequestedWrapper>
                    <BlueWarningIcon />
                </MasterLevelRequestedWrapper>
            </Tooltip>
        ) : (
            <></>
        );
    }, [isMasterLevelRequested]);

    const companyImportantMemo = useMemo(() => {
        return isCompanyImportant ? (
            <Tooltip title={t('tooltips.organizationPriority')}>
                <CompanyImportantWrapper>
                    <ClipboardCheckIcon />
                </CompanyImportantWrapper>
            </Tooltip>
        ) : (
            <></>
        );
    }, [isCompanyImportant]);

    const assignedControlMemo = useMemo(() => {
        return (
            isAssignedControlActive &&
            toggleAssign && (
                <AssignedControl
                    assignment={{
                        assignerId: assignment.assignerId,
                        assignerName: assignment.assignerName,
                        assignerRole: assignment.assignerRole,
                        date: assignment.date,
                        inProgressForWeeks:
                            userOutcomeState === ECompetenceState.IN_PROGRESS && assignment.streak !== undefined
                                ? assignment.streak
                                : undefined,
                        maintainedForWeeks:
                            userOutcomeState === ECompetenceState.MAINTAIN && assignment.streak !== undefined
                                ? assignment.streak
                                : undefined
                    }}
                    isAssigned={isSkillAssigned}
                    toggleAssign={toggleAssign}
                    translations={translations}
                    isActionDisabled={isActionDisabled || isPostAssignOutcomeLoading}
                    isCardMode
                    index={index}
                />
            )
        );
    }, [assignment, toggleAssign, isSkillAssigned, isPostAssignOutcomeLoading, isActionDisabled]);

    const onToggleDescriptionKeyDown = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter' && toggleDescriptionRef.current) {
                toggleDescriptionRef.current.click();
            }
            e.stopPropagation();
        },
        [id, isExpanded, collapse, expand, index]
    );

    const descriptionControlMemo = useMemo(() => {
        return (
            <ShowDescriptionBox
                id={`description-control-${index}`}
                tabIndex={-1}
                className={index !== undefined && index >= 0 ? `view-description-${index}` : 'view-description'}
                onClick={isExpanded ? collapse : expand}
                onKeyDown={onToggleDescriptionKeyDown}
                ref={toggleDescriptionRef}
            >
                {isExpanded
                    ? isLgDown
                        ? t('skill.viewLess')
                        : t('skill.hideDescription')
                    : isLgDown
                      ? t('skill.viewMore')
                      : t('skill.showDescription')}
            </ShowDescriptionBox>
        );
    }, [isExpanded, collapse, expand, isLgDown, isMdDown, index]);

    const onOutcomeClick = useCallback(() => {
        if (outcomeClickHandler && !isPostAssignOutcomeLoading) outcomeClickHandler(id.toString());
    }, [id, isPostAssignOutcomeLoading]);

    const onOutcomeKeyDown = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter' && outcomeClickHandler) outcomeClickHandler(id.toString());
            const currentElement = e.target as HTMLElement;
            if (e.key === 'ArrowDown') {
                if (index !== undefined && index >= 0) {
                    const nextElement = currentElement?.nextElementSibling;
                    if (nextElement) {
                        nextElement.setAttribute('tabindex', '0');
                        if (currentElement) currentElement?.setAttribute('tabindex', '-1');
                        (nextElement as HTMLElement).focus();
                    } else {
                        if (currentElement) currentElement.setAttribute('tabindex', '-1');
                    }
                }
            }
            if (e.key === 'ArrowUp') {
                if (index !== undefined && index >= 0) {
                    const nextElement = currentElement?.previousElementSibling;
                    if (nextElement) {
                        nextElement.setAttribute('tabindex', '0');
                        if (currentElement) currentElement?.setAttribute('tabindex', '-1');
                        (nextElement as HTMLElement).focus();
                    } else {
                        if (currentElement) currentElement.setAttribute('tabindex', '-1');
                    }
                }
            }
            if (e.key === 'ArrowRight') {
                if (index !== undefined && index >= 0) {
                    const descriptionControl = document.getElementById(`description-control-${index}`);
                    const assignControl = document.getElementById(`assign-control-${index}`);
                    const unassignControl = document.getElementById(`unassign-control-${index}`);
                    if (descriptionControl) descriptionControl.setAttribute('tabindex', '0');
                    if (assignControl) assignControl.setAttribute('tabindex', '0');
                    if (unassignControl) unassignControl.setAttribute('tabindex', '0');
                    if (assignControl) assignControl?.focus();
                    else if (unassignControl) unassignControl?.focus();
                    else if (descriptionControl) descriptionControl?.focus();
                }
            }
        },
        [id, index]
    );

    const descriptionMemo = useMemo(() => {
        if (!description) return '';
        if (Array.isArray(description)) {
            return description.map((descriptionItem) => {
                return (
                    <DescriptionSegment key={descriptionItem} dangerouslySetInnerHTML={{ __html: descriptionItem }} />
                );
            });
        }
        if (description) {
            return <DescriptionSegment dangerouslySetInnerHTML={{ __html: description }} />;
        }
        return '';
    }, [description]);

    return (
        <RootBox
            className={`outcome-card-${index ?? 0}`}
            id={`outcome-card-${index}`}
            tabIndex={index === 0 ? 0 : -1}
            onKeyDown={onOutcomeKeyDown}
            onClick={onOutcomeClick}
            isDescriptionExpanded={isExpanded}
            isDisabled={isPostAssignOutcomeLoading}
        >
            <FirstRowBox isHoverModeActive={true}>
                {!isMdDown &&
                    totalSkillNumber !== undefined &&
                    totalInProgressSkillNumber !== undefined &&
                    totalAchievedSkillNumber !== undefined && (
                        <ImageBox id={`outcome-image-${index}`}>{outcomeBadgeMemo}</ImageBox>
                    )}
                <InfoAssignedControlWrapper>
                    <InfoBox>
                        <InfoTextBox variant='subtitle2'>{name}</InfoTextBox>
                        <AppsBox>
                            <FooterActionBox>
                                {!isLgDown && appIconsMemo}
                                {!isLgDown && (
                                    <FooterActionBox className={`view-description-${index} outcome-indicators`}>
                                        <OrgPriorityMasterLevelBox className='org-priority-master-level-box'>
                                            {!isLgDown && isCompanyImportant && companyImportantMemo}
                                            {!isLgDown && isMasterLevelRequested && masterLevelRequestedMemo}
                                        </OrgPriorityMasterLevelBox>
                                        {!isLgDown && descriptionControlMemo}
                                    </FooterActionBox>
                                )}
                            </FooterActionBox>
                        </AppsBox>
                    </InfoBox>
                    <AssignedControlWrapper>
                        {isAssignedControlActive && !isMdDown && assignedControlMemo}
                    </AssignedControlWrapper>
                </InfoAssignedControlWrapper>
            </FirstRowBox>
            {isLgDown && !isMdDown && (
                <ActionControlRows>
                    <ActionControlRow>
                        {appIconsMemo}
                        <OrgPriorityMasterLevelBox className='org-priority-master-level-box'>
                            <div className='outcome-indicators'>{companyImportantMemo}</div>
                            <div className='outcome-indicators'>{masterLevelRequestedMemo}</div>
                        </OrgPriorityMasterLevelBox>
                    </ActionControlRow>
                    <DescriptionActionControlRow className={`view-description-${index}`}>
                        {descriptionControlMemo}
                    </DescriptionActionControlRow>
                </ActionControlRows>
            )}
            {isMdDown && (
                <MdDownActionControlRow>
                    <MdDownChartDescriptionBox>
                        {outcomeBadgeMemo}
                        <div className={`view-description-${index}`}>{descriptionControlMemo}</div>
                    </MdDownChartDescriptionBox>
                    {assignedControlMemo}
                </MdDownActionControlRow>
            )}
            {isExpanded && (
                <CollapsableRowBox
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    isDescriptionExpanded={isExpanded}
                >
                    {badges && <BadgesBox className={`badges-box-${index}`}>{badgesMemo}</BadgesBox>}
                    <Description variant='body2'>{descriptionMemo}</Description>
                </CollapsableRowBox>
            )}
        </RootBox>
    );
};

export default OutcomeCard;
