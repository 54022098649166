import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getTokenAndHeaders } from './helpers/apiUtil';
import useAuthParams from '../hooks/useAuthParams';
import { IAuthParams } from '../interfaces/IAuthParams';
import { apiInstance } from './helpers/apiInstance';
import { ITenantAllUserVM } from '../interfaces/views/ITenantAllUserVM';
import { ITenantHistoryVM } from '../interfaces/views/ITenantHistoryVM';
import { mapTenantHistoryData, mapTenantJobTaskStatus, mapTenantStatus } from './helpers/mappers';
import { ITenantJobTaskStatusVM } from '../interfaces/views/ITenantJobTaskStatusVM';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';
import { ITenantLicenseSettingsVM } from '../interfaces/views/ITenantLicenseSettingsVM';
import { ITenantStatusVM } from '../interfaces/views/ITenantStatusVM';
import { ITenantLicensedUserVM } from '../interfaces/views/ITenantLicensedUserVM';

export interface IPostMotwLaunchedRequest {
    motwId: string;
}

// GET TENANT ALL USERS
export const getTenantAllUsers = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`/tenant/allUsers`, headers);
    return data;
};

export const useGetTenantAllUsersQuery: () => UseQueryResult<ITenantAllUserVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.TENANT_FETCH_USERS],
        queryFn: async () => {
            return getTenantAllUsers(authParams);
        },
        refetchOnWindowFocus: false
    });
};

// GET TENANT LICENSED USERS
export const getTenantLicensedUsers = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`/tenant/licensedUsers`, headers);
    return data;
};

export const useGetTenantLicensedUsersQuery: () => UseQueryResult<ITenantLicensedUserVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.TENANT_FETCH_LICENSED_USERS],
        queryFn: async () => {
            return getTenantLicensedUsers(authParams);
        },
        refetchOnWindowFocus: false
    });
};
// GET TENANT HISTORIES
export const getTenantHistories = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`/tenantHistories`, headers);
    return mapTenantHistoryData(data.tenantHistoryStatuses);
};

export const useGetTenantHistoriesQuery: () => UseQueryResult<ITenantHistoryVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.TENANT_FETCH_HISTORY],
        queryFn: async () => {
            return getTenantHistories(authParams);
        },
        refetchOnWindowFocus: false,
        enabled: false
    });
};

// GET OUTCOME TENANT HISTORIES
export const getOutcomeTenantHistories = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`/tenantHistories/outcomes`, headers);
    return mapTenantHistoryData(data.tenantHistoryStatuses);
};

export const useGetOutcomeTenantHistoriesQuery: () => UseQueryResult<ITenantHistoryVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.TENANT_FETCH_OUTCOME_HISTORIES],
        queryFn: async () => {
            return getOutcomeTenantHistories(authParams);
        },
        refetchOnWindowFocus: false,
        enabled: false
    });
};

// GET SINGLE TENANT HISTORY
export const getTenantJobTaskStatus = async (authParams: IAuthParams, tenantJobId?: string) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`/tenantHistories/${tenantJobId}`, headers);
    return mapTenantJobTaskStatus(data.tenantHistoryUsers);
};

export const useGetTenantJobTaskStatusQuery: (
    tenantJobId?: string
) => UseQueryResult<ITenantJobTaskStatusVM[], Error> = (tenantJobId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.TENANT_FETCH_JOS_TASK_STATUS],
        queryFn: async () => {
            return getTenantJobTaskStatus(authParams, tenantJobId);
        },
        refetchOnWindowFocus: false,
        enabled: false
    });
};

// GET SINGLE TENANT STATUS
export const getTenantStatus = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`/tenant/status`, headers);
    return mapTenantStatus(data);
};

export const useGetTenantStatusQuery: () => UseQueryResult<ITenantStatusVM, Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.TENANT_FETCH_STATUS],
        queryFn: async () => {
            return getTenantStatus(authParams);
        },
        refetchOnWindowFocus: false
    });
};

// GET SINGLE TENANT LICENSE SETTINGS
export const getTenantLicenseSettings = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`/tenant/licenseSettings`, headers);
    return data;
};

export const useGetTenantLicenseSettingsQuery: () => UseQueryResult<ITenantLicenseSettingsVM, Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.TENANT_FETCH_LICENSE_SETTINGS],
        queryFn: async () => {
            return getTenantLicenseSettings(authParams);
        },
        refetchOnWindowFocus: false
    });
};
