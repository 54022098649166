import { FC } from 'react';

const NuliaPurpleLogo: FC = () => {
    return (
        <svg width='187' height='39' viewBox='0 0 187 39' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M28.05 37.5917H23.2541V11.1042L41.269 28.5323C44.5334 31.2948 47.5963 32.6354 50.6189 32.6354C57.6315 32.6354 63.3543 26.8667 63.3543 19.7979C63.3543 12.7292 57.6315 6.96044 50.6189 6.96044H46.6291V2.16669H50.6189C60.2914 2.16669 68.1502 10.0886 68.1502 19.8386C68.1502 29.5886 60.2914 37.5104 50.6189 37.5104C46.3873 37.5104 42.3168 35.7636 38.1254 32.1886L38.0045 32.1073L28.05 22.4792V37.5917ZM20.7151 37.5511H17.5312C7.85884 37.5511 0 29.6292 0 19.8792C0 10.1292 7.85884 2.20731 17.5312 2.20731C18.0601 2.20731 18.5889 2.243 19.1509 2.28092L19.2642 2.28856C22.4481 2.61356 25.4707 3.79169 28.05 5.74169L28.2112 5.86356L29.9845 7.44794L39.4554 16.3042V2.20731H44.2916V27.3542L26.72 11.0229L25.0677 9.56044C23.2138 8.17919 21.0375 7.36669 18.7806 7.12294C18.3776 7.08231 17.9343 7.04169 17.5312 7.04169C10.5188 7.08231 4.79591 12.8511 4.79591 19.9198C4.79591 26.9886 10.5188 32.7573 17.5312 32.7573H20.7151V37.5511ZM83.6664 7.40731L92.3312 22.7229L92.1297 7.40731H96.6838V28.7354H91.5252L82.417 12.6073L82.6185 28.7354H78.0644V7.40731H83.6664ZM114.537 25.5667C110.87 25.5667 109.782 23.9823 109.782 20.2042V7.40731H105.349V20.2854C105.349 22.9667 105.59 24.8354 106.558 26.0136C108.291 28.0854 110.87 28.9386 114.537 28.9386C118.205 28.9386 120.744 28.0854 122.477 26.0136C123.444 24.8761 123.726 23.0073 123.726 20.2854V7.40731H119.293V20.2042C119.293 23.9823 118.205 25.5667 114.537 25.5667ZM133.882 26.5417C132.27 24.8354 131.948 22.9667 131.948 20.1229V7.40731H136.381V19.1073V19.9198C136.381 21.6667 136.421 22.9667 137.106 23.7792C138.033 24.9167 139.323 25.1604 141.217 25.1604H146.456V28.6948H140.814C137.792 28.7761 135.776 28.5323 133.882 26.5417ZM153.308 7.40731V28.7761L157.942 28.7354V7.40731H153.308ZM177.569 7.40731L186.597 28.7354H181.64L179.705 23.6573H170.517L168.622 28.7354H163.907L172.854 7.40731H177.569ZM171.927 19.8792H178.255L175.03 11.6729L171.927 19.8792Z'
                fill='#5C4F9C'
            />
            <mask
                id='mask0_1025_277'
                // style='mask-type:luminance'
                maskUnits='userSpaceOnUse'
                x='0'
                y='2'
                width='187'
                height='36'
            >
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M28.05 37.5917H23.2541V11.1042L41.269 28.5323C44.5334 31.2948 47.5963 32.6354 50.6189 32.6354C57.6315 32.6354 63.3543 26.8667 63.3543 19.7979C63.3543 12.7292 57.6315 6.96044 50.6189 6.96044H46.6291V2.16669H50.6189C60.2914 2.16669 68.1502 10.0886 68.1502 19.8386C68.1502 29.5886 60.2914 37.5104 50.6189 37.5104C46.3873 37.5104 42.3168 35.7636 38.1254 32.1886L38.0045 32.1073L28.05 22.4792V37.5917ZM20.7151 37.5511H17.5312C7.85884 37.5511 0 29.6292 0 19.8792C0 10.1292 7.85884 2.20731 17.5312 2.20731C18.0601 2.20731 18.5889 2.243 19.1509 2.28092L19.2642 2.28856C22.4481 2.61356 25.4707 3.79169 28.05 5.74169L28.2112 5.86356L29.9845 7.44794L39.4554 16.3042V2.20731H44.2916V27.3542L26.72 11.0229L25.0677 9.56044C23.2138 8.17919 21.0375 7.36669 18.7806 7.12294C18.3776 7.08231 17.9343 7.04169 17.5312 7.04169C10.5188 7.08231 4.79591 12.8511 4.79591 19.9198C4.79591 26.9886 10.5188 32.7573 17.5312 32.7573H20.7151V37.5511ZM83.6664 7.40731L92.3312 22.7229L92.1297 7.40731H96.6838V28.7354H91.5252L82.417 12.6073L82.6185 28.7354H78.0644V7.40731H83.6664ZM114.537 25.5667C110.87 25.5667 109.782 23.9823 109.782 20.2042V7.40731H105.349V20.2854C105.349 22.9667 105.59 24.8354 106.558 26.0136C108.291 28.0854 110.87 28.9386 114.537 28.9386C118.205 28.9386 120.744 28.0854 122.477 26.0136C123.444 24.8761 123.726 23.0073 123.726 20.2854V7.40731H119.293V20.2042C119.293 23.9823 118.205 25.5667 114.537 25.5667ZM133.882 26.5417C132.27 24.8354 131.948 22.9667 131.948 20.1229V7.40731H136.381V19.1073V19.9198C136.381 21.6667 136.421 22.9667 137.106 23.7792C138.033 24.9167 139.323 25.1604 141.217 25.1604H146.456V28.6948H140.814C137.792 28.7761 135.776 28.5323 133.882 26.5417ZM153.308 7.40731V28.7761L157.942 28.7354V7.40731H153.308ZM177.569 7.40731L186.597 28.7354H181.64L179.705 23.6573H170.517L168.622 28.7354H163.907L172.854 7.40731H177.569ZM171.927 19.8792H178.255L175.03 11.6729L171.927 19.8792Z'
                    fill='white'
                />
            </mask>
            <g mask='url(#mask0_1025_277)'></g>
        </svg>
    );
};

export default NuliaPurpleLogo;
