import {
    ChangeEvent,
    createContext,
    FC,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
    PropsWithChildren
} from 'react';
import { useTranslation } from 'react-i18next';
import { isBefore } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { useGetAllSkillsQuery, usePostAssignSkill, usePostFavoriteSkill } from '../services/SkillQueryService';
import { IFilterCategories, IFilterCategoryValue } from '../ui/filters/filters/Filters';
import HeartIcon from '../assets/icons/HeartIcon';
import { ECompetenceState } from '../interfaces/enums/ECompetenceState';
import {
    appFilterCallback,
    categoryFilterCallback,
    EOrderDirection,
    ESkillSort,
    ISkillSortOrder,
    skillCategories
} from './util/filterCategories';
import { IUserSkillVM } from '../interfaces/views/IUserSkillVM';
import { EAssignmentType } from '../interfaces/enums/EAssignmentType';
import { ESkillLevel } from '../interfaces/enums/ESkillLevel';
import useFilterSearch from '../hooks/useFilterSearch';
import { EToastSeverity, useToastContextStateValue } from './ToastContext';
import { ErrorDTO } from '../interfaces/dtos/ErrorDTO';
import { ESkillsStatuses } from '../interfaces/ESkillsStatuses';
import { usePostUserRecommendations } from '../services/RecommendationService';
import { ERecommendationType } from '../interfaces/enums/ERecommendationType';
import { IRecommendationVM } from '../interfaces/views/IRecommendationVM';
import MinusIconSVG from '../assets/icons/MinusIcon';
import { useTabsStateValue } from './TabsContext';
import { ESkillType } from '../interfaces/enums/ESkillType';

interface ISkillStatusInfo {
    key: ESkillsStatuses;
    label: string;
    value?: number | string | null;
    icon?: React.ReactNode;
    circleColor?: string;
    isLoading?: boolean;
}

interface SkillsContextProps {
    fetchedSkills: IUserSkillVM[] | undefined;
    skills: IUserSkillVM[];
    isSkillsLoading: boolean;
    skillsStatusOverallInfo: ISkillStatusInfo[];
    searchText: string;
    changeSearchText: (e: ChangeEvent<HTMLInputElement>) => void;
    activeSkillsStatusOverallFilters: ESkillsStatuses[];
    changeSkillStatusOverallActiveFilters: (key: ESkillsStatuses) => void;
    onFilterValueChange: (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => void;
    setSortOrder: (sortOrder: ISkillSortOrder) => void;
    sortOrder: ISkillSortOrder;
    isError: boolean;
    refetchData: () => void;
    filterCategories: IFilterCategories[];
    setFilteredSkills: (list: IUserSkillVM[]) => void;
    toggleFavoriteCallback: (skillId: number) => void;
    toggleAssignCallback: (skillId: number, isAssigned: boolean) => void;
    emptyQuickFilters: () => void;
    isPostFavoriteSkillLoading: boolean;
    isPostAssignSkillLoading: boolean;
    isFetchedDataProcessed: boolean;
    recommendations?: IRecommendationVM[];
    isRecommendationsLoading: boolean;
    successRecommendationCallback: () => void;
}

const SkillsContext = createContext<SkillsContextProps>({} as SkillsContextProps);

interface IProps {}

export const SkillsProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const {
        data: fetchedSkills,
        isLoading: isSkillsLoading,
        isError,
        refetch,
        isRefetching: isSkillsRefetching
    } = useGetAllSkillsQuery();
    const { t } = useTranslation();
    const [skills, setSkills] = useState<IUserSkillVM[]>([]);
    const [filteredSkills, setFilteredSkills] = useState<IUserSkillVM[]>([]);
    const [activeSkillsStatusOverallFilters, setActiveSkillsStatusOverallFilters] = useState<ESkillsStatuses[]>([]);
    const [isFetchedDataProcessed, setFetchedDataProcessed] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        data: fetchedRecommendationsData,
        mutateAsync: mutateRecommendationsAsync,
        isPending: isRecommendationsLoading
    } = usePostUserRecommendations([ERecommendationType.ADD_SKILL, ERecommendationType.UPGRADE_SKILL]);
    const { changeSearchText } = useTabsStateValue();

    useEffect(() => {
        mutateRecommendationsAsync(undefined);
    }, []);

    useEffect(() => {
        const filterQueryParam = searchParams.get('filter');
        if (skills && filterQueryParam) {
            switch (filterQueryParam) {
                case ESkillsStatuses.ASSIGNED_SKILLS:
                    setActiveSkillsStatusOverallFilters((activeSkillsStatusOverallFilters) => {
                        return [...activeSkillsStatusOverallFilters, ESkillsStatuses.ASSIGNED_SKILLS];
                    });
                    break;
                case ESkillsStatuses.IN_PROGRESS:
                    setActiveSkillsStatusOverallFilters((activeSkillsStatusOverallFilters) => {
                        return [...activeSkillsStatusOverallFilters, ESkillsStatuses.IN_PROGRESS];
                    });
                    break;
                case ESkillsStatuses.ATTAINED:
                    setActiveSkillsStatusOverallFilters((activeSkillsStatusOverallFilters) => {
                        return [...activeSkillsStatusOverallFilters, ESkillsStatuses.ATTAINED];
                    });
                    break;
                case ESkillsStatuses.NEED_ATTENTION:
                    setActiveSkillsStatusOverallFilters((activeSkillsStatusOverallFilters) => {
                        return [...activeSkillsStatusOverallFilters, ESkillsStatuses.NEED_ATTENTION];
                    });
                    break;
                case ESkillsStatuses.PERSONAL_FOCUS:
                    setActiveSkillsStatusOverallFilters((activeSkillsStatusOverallFilters) => {
                        return [...activeSkillsStatusOverallFilters, ESkillsStatuses.PERSONAL_FOCUS];
                    });
                    break;
            }
        }
    }, [searchParams, skills]);

    const onAnythingChange = (skillsNeedToBeFiltered: IUserSkillVM[], runSearchText?: boolean) => {
        let newFilteredSkills = [...skillsNeedToBeFiltered];
        newFilteredSkills = filterSkillsBySkillStatusOverall(newFilteredSkills);
        if (runSearchText) {
            newFilteredSkills = instantFilterByText(searchText, newFilteredSkills);
            newFilteredSkills = sortByOrder(newFilteredSkills);
            newFilteredSkills = onFilterValueChange(filterCategories, undefined, true, newFilteredSkills);
            setFilteredSkills(newFilteredSkills);
        } else {
            newFilteredSkills = sortByOrder(newFilteredSkills);
            newFilteredSkills = onFilterValueChange(filterCategories, undefined, true, newFilteredSkills);
            setFilteredSkills(newFilteredSkills);
        }
        return newFilteredSkills;
    };

    const { searchText, setSearchText, instantFilterByText } = useFilterSearch<IUserSkillVM>({
        data: skills,
        dataSerachablePropertyName: 'title',
        onChangeCallback: onAnythingChange,
        setDataCallback: setFilteredSkills
    });

    const { searchText: headerInputSearchText } = useTabsStateValue();
    useEffect(() => {
        setSearchText(headerInputSearchText);
    }, [headerInputSearchText]);

    const [skillsStatusOverallInfo, setSkillsStatusOverallInfo] = useState<ISkillStatusInfo[]>([
        {
            key: ESkillsStatuses.IN_PROGRESS,
            label: t('skills.statuses.inProgress'),
            circleColor: '#FFBF44',
            value: null,
            isLoading: true
        },
        {
            key: ESkillsStatuses.PERSONAL_FOCUS,
            label: t('skills.statuses.focus'),
            icon: <HeartIcon liked />,
            value: null,
            isLoading: true
        },
        {
            key: ESkillsStatuses.NEED_ATTENTION,
            label: t('skills.statuses.needAttention'),
            circleColor: '#ED8000',
            value: null,
            isLoading: true
        },
        {
            key: ESkillsStatuses.ATTAINED,
            label: t('skills.statuses.attained'),
            circleColor: 'rgba(146, 185, 35, 1)',
            value: null,
            isLoading: true
        },
        {
            key: ESkillsStatuses.ASSIGNED_SKILLS,
            label: t('skills.statuses.assigned'),
            icon: <MinusIconSVG />,
            value: null,
            isLoading: true
        }
    ]);
    const [sortOrder, setSortOrder] = useState<ISkillSortOrder>({
        sortBy: ESkillSort.NAME,
        direction: EOrderDirection.ASC
    });
    const [filterCategories, setFilterCategories] = useState<IFilterCategories[]>(skillCategories);
    const {
        mutateAsync: mutatePostFavoriteSkillAsync,
        isPending: isPostFavoriteSkillLoading,
        error: errorPostFavoriteSkill
    } = usePostFavoriteSkill();
    const {
        mutateAsync: mutatePostAssignSkillAsync,
        error: errorPostAssignSkill,
        isPending: isPostAssignSkillLoading
    } = usePostAssignSkill();
    const { setToastMessage } = useToastContextStateValue();
    const sortOrderRef = useRef({
        sortBy: ESkillSort.NAME,
        direction: EOrderDirection.ASC
    });
    const activeFiltersRef = useRef<string[]>([]);
    const activeSkillsStatusOverallFiltersRef = useRef<ESkillsStatuses[]>([]);

    useEffect(() => {
        sortOrderRef.current = sortOrder;
    }, [sortOrder]);

    useEffect(() => {
        if (errorPostAssignSkill && errorPostAssignSkill instanceof Error) {
            const errorDTO = errorPostAssignSkill as ErrorDTO;
            if (errorDTO.response?.data?.code === 500) {
                setToastMessage({
                    isOpen: true,
                    message: t('errors.skill.errorAssignSkillPartOfAssignedOutcome'),
                    severity: EToastSeverity.ERROR
                });
            } else {
                setToastMessage({
                    isOpen: true,
                    message: t('errors.skill.errorAssignSkill'),
                    severity: EToastSeverity.ERROR
                });
            }
        }
    }, [errorPostAssignSkill]);

    useEffect(() => {
        if (errorPostFavoriteSkill && errorPostFavoriteSkill instanceof Error) {
            if (errorPostFavoriteSkill?.status === 422) {
                setToastMessage({
                    isOpen: true,
                    message: t('errors.skill.errorFavoriteSkillLimit'),
                    severity: EToastSeverity.WARNING
                });
            } else {
                setToastMessage({
                    isOpen: true,
                    message: t('errors.skill.errorFavoriteSkill'),
                    severity: EToastSeverity.ERROR
                });
            }
        }
    }, [errorPostFavoriteSkill]);

    useEffect(() => {
        if (fetchedSkills) {
            setSkills(fetchedSkills);
            setFetchedDataProcessed(true);
        }
    }, [fetchedSkills]);

    useEffect(() => {
        if (isError) {
            setSkillsStatusOverallInfo((skillsStatusOverallInfo) => {
                return skillsStatusOverallInfo.map((status) => {
                    return {
                        ...status,
                        isLoading: false,
                        value: '-'
                    };
                });
            });
        }
    }, [isError]);

    const sortByOrder = (skills: IUserSkillVM[]) => {
        let newSkills = [...skills];
        const sortOrder = sortOrderRef.current;
        switch (sortOrder.sortBy) {
            case ESkillSort.NAME:
                newSkills = skills.sort((a, b) => {
                    if (sortOrder.direction === EOrderDirection.ASC) return ('' + a.title).localeCompare(b.title);
                    return ('' + b.title).localeCompare(a.title);
                });
                return newSkills;
            case ESkillSort.SCORE:
                newSkills = skills.sort((a, b) => {
                    if (sortOrder.direction === EOrderDirection.ASC) {
                        if (a.skillType === ESkillType.BEST_PRACTICE) return -1;
                        return a.score.score < b.score.score ? -1 : 1;
                    } else {
                        if (a.skillType === ESkillType.BEST_PRACTICE) return 1;
                        return a.score.score < b.score.score ? 1 : -1;
                    }
                });
                return newSkills;
            case ESkillSort.ASSIGNED_DATE:
                newSkills = skills.sort((a, b) => {
                    const aLevel = a.assignment.level;
                    const bLevel = b.assignment.level;
                    if (sortOrder.direction === EOrderDirection.ASC) {
                        if (
                            (aLevel === ESkillLevel.NONE && bLevel === ESkillLevel.NONE) ||
                            (aLevel !== ESkillLevel.NONE && bLevel !== ESkillLevel.NONE)
                        )
                            return isBefore(new Date(a.assignment.date), new Date(b.assignment.date)) ? -1 : 1;
                        if (aLevel === ESkillLevel.NONE && bLevel !== ESkillLevel.NONE) return 1;
                        if (aLevel !== ESkillLevel.NONE && bLevel === ESkillLevel.NONE) return -1;
                        return 1;
                    }
                    if (
                        (aLevel === ESkillLevel.NONE && bLevel === ESkillLevel.NONE) ||
                        (aLevel !== ESkillLevel.NONE && bLevel !== ESkillLevel.NONE)
                    )
                        return isBefore(new Date(a.assignment.date), new Date(b.assignment.date)) ? 1 : -1;
                    if (aLevel === ESkillLevel.NONE && bLevel !== ESkillLevel.NONE) return 1;
                    if (aLevel !== ESkillLevel.NONE && bLevel === ESkillLevel.NONE) return -1;
                    return -1;
                });
                return newSkills;
            case ESkillSort.WEEKS_IN_PROGRESS:
                newSkills = skills.sort((a, b) => {
                    let aWeeksCount: number = -1;
                    let bWeeksCount: number = -1;
                    if (
                        (a.assignment.level === ESkillLevel.MASTER &&
                            a.assignment.masterState === ECompetenceState.IN_PROGRESS) ||
                        (a.assignment.level === ESkillLevel.PRODUCER &&
                            a.assignment.producerState === ECompetenceState.IN_PROGRESS)
                    ) {
                        aWeeksCount = a.assignment.streak;
                    }
                    if (
                        (b.assignment.level === ESkillLevel.MASTER &&
                            b.assignment.masterState === ECompetenceState.IN_PROGRESS) ||
                        (b.assignment.level === ESkillLevel.PRODUCER &&
                            b.assignment.producerState === ECompetenceState.IN_PROGRESS)
                    ) {
                        bWeeksCount = b.assignment.streak;
                    }
                    if (sortOrder.direction === EOrderDirection.ASC) return aWeeksCount < bWeeksCount ? -1 : 1;
                    return aWeeksCount < bWeeksCount ? 1 : -1;
                });
                return newSkills;
            default:
                return newSkills;
        }
    };

    const changeSkillStatusOverallActiveFilters = useCallback(
        (key: ESkillsStatuses) => {
            setActiveSkillsStatusOverallFilters((activeStatusList) => {
                setSearchParams(
                    (prev) => {
                        if (activeStatusList.includes(key)) {
                            prev.set(
                                'filter',
                                encodeURIComponent(
                                    [...new Set(activeStatusList)].filter((statusKey) => statusKey !== key).join('-')
                                )
                            );
                            const newValues = prev.getAll('filter');
                            if (newValues.length === 1 && newValues[0] === '') {
                                prev.delete('filter');
                            }
                        } else {
                            prev.set('filter', encodeURIComponent([key].join('-')));
                        }
                        return prev;
                    },
                    { replace: true }
                );
                if (activeStatusList.includes(key)) {
                    return [];
                }
                return [key];
            });
        },
        [filteredSkills, activeSkillsStatusOverallFilters]
    );

    useEffect(() => {
        activeSkillsStatusOverallFiltersRef.current = activeSkillsStatusOverallFilters;
    }, [activeSkillsStatusOverallFilters]);

    useEffect(() => {
        if (fetchedSkills) {
            const skills = fetchedSkills;
            setFilteredSkills(skills);
            calculateSkillStatusOverallInfo();
            const apps = new Set<string>();
            skills.forEach((skill) => {
                skill.apps.forEach((app) => {
                    apps.add(app.name);
                });
            });
            const categories = new Set<string>();
            skills.forEach((skill) => {
                skill.categories.forEach((category) => {
                    categories.add(category);
                });
            });

            filterCategories.forEach((filterCategory) => {
                if (filterCategory.name === 'App') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    apps.forEach((app) => {
                        categoryValues.push({
                            key: app,
                            name: app,
                            callback: appFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
                if (filterCategory.name === 'Category') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    categories.forEach((category) => {
                        categoryValues.push({
                            key: category,
                            name: category,
                            callback: categoryFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
            });
            setFilterCategories([...filterCategories]);
        }
    }, [fetchedSkills]);

    const onFilterValueChange = (
        filterCategories: IFilterCategories[],
        newActiveFilters?: string[], // if not undefined this callback is called by Filter component, else it's called by onAnythingChange
        dontRunAnythingChange?: boolean,
        skillsToFilter?: IUserSkillVM[] // use this array for filtering if not undefined, else use current filteredSkills from this context
    ) => {
        let newFilteredSkills: IUserSkillVM[] = [];
        if (skillsToFilter) newFilteredSkills = skillsToFilter;
        else newFilteredSkills = [...skills];
        let currentActiveFilters = activeFiltersRef.current;
        if (newActiveFilters) {
            currentActiveFilters = newActiveFilters;
            activeFiltersRef.current = newActiveFilters;
        }
        filterCategories.forEach((filterCategory) => {
            if (filterCategory.values) {
                filterCategory.values.forEach((filterCategoryValue) => {
                    if (filterCategoryValue.callback && currentActiveFilters.includes(filterCategoryValue.key)) {
                        newFilteredSkills = newFilteredSkills.filter((skill) => {
                            if (filterCategoryValue.callback) {
                                const isValid = filterCategoryValue.callback(
                                    skill,
                                    filterCategoryValue.name,
                                    filterCategoryValue.key
                                );
                                return isValid;
                            }
                            return false;
                        });
                    }
                });
            }
        });
        if (!dontRunAnythingChange) onAnythingChange(newFilteredSkills, true);
        return newFilteredSkills;
    };

    const refetchData = useCallback(async () => {
        try {
            setSkillsStatusOverallInfo((skillsStatusOverallInfo) => {
                return skillsStatusOverallInfo.map((skill) => {
                    return {
                        ...skill,
                        isLoading: true
                    };
                });
            });
            await refetch();
            setSkillsStatusOverallInfo((skillsStatusOverallInfo) => {
                return skillsStatusOverallInfo.map((skill) => {
                    return {
                        ...skill,
                        isLoading: false
                    };
                });
            });
        } catch (e) {}
    }, [refetch]);

    const findAssignedSkills = (skills: IUserSkillVM[]) => {
        return skills.filter((skill) => skill.isAssigned);
    };

    const findInProgressSkills = (skills: IUserSkillVM[]) => {
        return skills.filter((skill) => {
            return (
                (skill.assignment.level === ESkillLevel.PRODUCER &&
                    skill.assignment.producerState === ECompetenceState.IN_PROGRESS) ||
                (skill.assignment.level === ESkillLevel.MASTER &&
                    skill.assignment.masterState === ECompetenceState.IN_PROGRESS)
            );
        });
    };

    const findNeedAttentionSkills = (skills: IUserSkillVM[]) => {
        return skills.filter((skill) => {
            return (
                (skill.assignment.level === ESkillLevel.PRODUCER &&
                    skill.assignment.producerState === ECompetenceState.NEEDS_ATTENTITON) ||
                (skill.assignment.level === ESkillLevel.MASTER &&
                    skill.assignment.masterState === ECompetenceState.NEEDS_ATTENTITON)
            );
        });
    };

    const findAttainedSkills = (skills: IUserSkillVM[]) => {
        return skills.filter((skill) => {
            return (
                (skill.assignment.level === ESkillLevel.PRODUCER &&
                    (skill.assignment.producerState === ECompetenceState.NEEDS_ATTENTITON ||
                        skill.assignment.producerState === ECompetenceState.MAINTAIN)) ||
                (skill.assignment.level === ESkillLevel.MASTER &&
                    (skill.assignment.masterState === ECompetenceState.NEEDS_ATTENTITON ||
                        skill.assignment.masterState === ECompetenceState.MAINTAIN))
            );
        });
    };

    const findFocusedSkills = (skills: IUserSkillVM[]) => {
        return skills.filter((skill) => {
            return skill.isAssigned && skill.isUserFavorite;
        });
    };

    const filterSkillsBySkillStatusOverall = (skills: IUserSkillVM[]) => {
        if (skills) {
            let newSkills = [...skills];
            activeSkillsStatusOverallFiltersRef.current.forEach((statusFilter) => {
                switch (statusFilter) {
                    case ESkillsStatuses.ASSIGNED_SKILLS:
                        newSkills = findAssignedSkills(newSkills);
                        break;
                    case ESkillsStatuses.IN_PROGRESS:
                        newSkills = findInProgressSkills(newSkills);
                        break;
                    case ESkillsStatuses.PERSONAL_FOCUS:
                        newSkills = findFocusedSkills(newSkills);
                        break;
                    case ESkillsStatuses.NEED_ATTENTION:
                        newSkills = findNeedAttentionSkills(newSkills);
                        break;
                    case ESkillsStatuses.ATTAINED:
                        newSkills = findAttainedSkills(newSkills);
                        break;
                }
            });
            return newSkills;
        }
        return skills;
    };

    const calculateSkillStatusOverallInfo = (filterSkills?: IUserSkillVM[]) => {
        const skillsToFilter = filterSkills || skills;
        if (skillsToFilter) {
            let numberOfAssignedSkills: number = 0;
            let numberOfPersonalFocusSkills: number = 0;
            let numberOfInProgressSkills: number = 0;
            let numberOfNeedsAttentionSkills: number = 0;
            let numberOfAttainedSkills: number = 0;
            numberOfAssignedSkills = findAssignedSkills(skillsToFilter).length;
            numberOfPersonalFocusSkills = findFocusedSkills(skillsToFilter).length;
            numberOfInProgressSkills = findInProgressSkills(skillsToFilter).length;
            numberOfNeedsAttentionSkills = findNeedAttentionSkills(skillsToFilter).length;
            numberOfAttainedSkills = findAttainedSkills(skillsToFilter).length;
            setSkillsStatusOverallInfo((currentSkillsStatusOverall) => {
                let newSkillsStatusOverall = [...currentSkillsStatusOverall];
                newSkillsStatusOverall = newSkillsStatusOverall.map((sso) => {
                    switch (sso.key) {
                        case ESkillsStatuses.ASSIGNED_SKILLS:
                            sso.value = numberOfAssignedSkills;
                            break;
                        case ESkillsStatuses.PERSONAL_FOCUS:
                            sso.value = numberOfPersonalFocusSkills;
                            break;
                        case ESkillsStatuses.IN_PROGRESS:
                            sso.value = numberOfInProgressSkills;
                            break;
                        case ESkillsStatuses.NEED_ATTENTION:
                            sso.value = numberOfNeedsAttentionSkills;
                            break;
                        case ESkillsStatuses.ATTAINED:
                            sso.value = numberOfAttainedSkills;
                            break;
                    }
                    return {
                        ...sso,
                        isLoading: false
                    };
                });
                return newSkillsStatusOverall;
            });
        }
    };

    const toggleFavoriteCallback = useCallback(
        async (skillId: number) => {
            try {
                const responseData = await mutatePostFavoriteSkillAsync({
                    skillId: skillId.toString()
                });
                setSkills((skills) => {
                    return skills.map((skill) => {
                        if (skill.id !== skillId) return skill;
                        return {
                            ...skill,
                            isUserFavorite: responseData.isFavorite
                        };
                    });
                });
            } catch (e) {}
        },
        [skills, setFilteredSkills, filteredSkills]
    );

    const toggleAssignCallback = useCallback(
        async (skillId: number, isAssigned: boolean) => {
            try {
                const responseData = await mutatePostAssignSkillAsync({
                    skillId: skillId.toString(),
                    assignmentType: isAssigned ? EAssignmentType.ASSIGN : EAssignmentType.UNASSIGN
                });
                setSkills((skills) => {
                    return skills.map((skill) => {
                        if (skill.id !== skillId) return skill;
                        return {
                            ...skill,
                            assignment: {
                                ...responseData
                            },
                            isAssigned: responseData.level !== ESkillLevel.NONE,
                            isUserFavoriteControlDisabled: responseData.level === ESkillLevel.NONE
                        };
                    });
                });
                if (isAssigned) {
                    setToastMessage({
                        isOpen: true,
                        severity: EToastSeverity.SUCCESS,
                        message: t('success.skills.successAddSkill')
                    });
                } else {
                    setToastMessage({
                        isOpen: true,
                        severity: EToastSeverity.SUCCESS,
                        message: t('success.skills.successRemoveSkill')
                    });
                }
            } catch (err) {}
        },
        [skills, setFilteredSkills, filteredSkills]
    );

    useEffect(() => {
        onAnythingChange(skills, true);
    }, [activeSkillsStatusOverallFilters, filterCategories, sortOrder, skills]);

    useEffect(() => {
        calculateSkillStatusOverallInfo(skills);
    }, [skills]);

    const emptyQuickFilters = useCallback(() => {
        setActiveSkillsStatusOverallFilters([]);
    }, []);

    const successRecommendationCallback = () => {
        refetchData();
    };

    const contextState: SkillsContextProps = {
        fetchedSkills,
        searchText,
        changeSearchText,
        activeSkillsStatusOverallFilters,
        changeSkillStatusOverallActiveFilters,
        skills: filteredSkills,
        isSkillsLoading: isSkillsLoading || isSkillsRefetching,
        skillsStatusOverallInfo,
        onFilterValueChange,
        setSortOrder,
        sortOrder,
        isError,
        refetchData,
        filterCategories,
        setFilteredSkills,
        toggleFavoriteCallback,
        toggleAssignCallback,
        emptyQuickFilters,
        isPostFavoriteSkillLoading,
        isPostAssignSkillLoading,
        isFetchedDataProcessed,
        recommendations: fetchedRecommendationsData,
        isRecommendationsLoading,
        successRecommendationCallback
    };

    return <SkillsContext.Provider value={contextState}>{children}</SkillsContext.Provider>;
};

export const useSkillsStateValue: () => SkillsContextProps = () => useContext(SkillsContext);

export default SkillsContext;
